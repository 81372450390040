<template>
	<div class="mod-config demo_box">
		<el-alert v-if="!projectType"
				title="请通过首页设置项目类型"
				type="error"
				effect="dark">
		</el-alert>
		<div v-if="projectType === '1'">
			<el-row :gutter="10">
				<el-col :span="19">
					<el-card class="blue_card">周报辅助数据管理(适用于船舶监理项目)</el-card>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<el-card class="blue_card">本周概括</el-card>
				</el-col>
				<el-col :span="4">
					<el-card class="blue_card">重要事项</el-card>
				</el-col>
				<el-col :span="4">
					<el-card ><el-button v-preventReClick @click="gotoProjectImportantList" type="text">重要事项描述</el-button></el-card>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<el-card class="blue_card">施工进度情况</el-card>
				</el-col>
				<el-col :span="4">
					<el-card class="blue_card">详细进度情况</el-card>
				</el-col>
				<el-col :span="4">
					<el-card><el-button v-preventReClick @click="gotoDetailedProgressList" type="text">施工进度详细描述</el-button></el-card>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<el-card class="blue_card height4">质量情况</el-card>
				</el-col>
				<el-col :span="5">
					<el-card class="blue_card height2">停止点检查（报告）情况</el-card>
					<el-card class="blue_card height2">巡查检查情况</el-card>
				</el-col>
				<el-col :span="5">
					<el-card class="blue_card">总体描述</el-card>
					<el-card class="blue_card">主要质量问题跟踪处理情况</el-card>
					<el-card class="blue_card">总体描述</el-card>
					<el-card class="blue_card">突出问题跟踪处理情况</el-card>
				</el-col>
				<el-col :span="5">
					<el-card><el-button v-preventReClick @click="gotoQualitySituationList('00')" type="text">总体描述</el-button></el-card>
					<el-card><el-button v-preventReClick @click="gotoQualitySituationList('10')" type="text">主要质量问题跟踪处理情况描述</el-button></el-card>
					<el-card><el-button v-preventReClick @click="gotoQualitySituationList('15')" type="text">总体描述</el-button></el-card>
					<el-card><el-button v-preventReClick @click="gotoQualitySituationList('20')" type="text">突出问题跟踪处理情况</el-button></el-card>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<el-card class="blue_card">设计修改及审图意见落实情况</el-card>
				</el-col>
				<el-col :span="5">
					<el-card><el-button v-preventReClick @click="gotoQualitySituationList('40')" type="text">设计修改及审图意见落实情况描述</el-button></el-card>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<el-card class="blue_card">HSE情況</el-card>
				</el-col>
				<el-col :span="5">
					<el-card><el-button v-preventReClick @click="gotoQualitySituationList('30')" type="text">HSE情況描述</el-button></el-card>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<el-card class="blue_card">其他需要说明的问题</el-card>
				</el-col>
				<el-col :span="5">
					<el-card><el-button v-preventReClick @click="gotoQualitySituationList('99')" type="text">其他需要说明的问题描述</el-button></el-card>
				</el-col>
			</el-row>
		</div>
		<div v-if="projectType === '2'">
			<el-row :gutter="10">
				<el-col :span="12">
					<el-card class="blue_card">周报辅助数据管理(适用于设备监理项目)</el-card>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<el-card class="blue_card">本周监理工作概括</el-card>
				</el-col>
				<el-col :span="4">
					<el-card class="blue_card">情况描述</el-card>
				</el-col>
				<el-col :span="4">
					<el-card ><el-button v-preventReClick @click="gotoQualitySituationList('80')" type="text">情况综述描述</el-button></el-card>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<el-card class="blue_card height3">进度情况</el-card>
				</el-col>
				<el-col :span="4">
					<el-card class="blue_card">进度分析</el-card>
					<el-card class="blue_card">设计进度情况</el-card>
					<el-card class="blue_card">生产及技术准备情况</el-card>
				</el-col>
				<el-col :span="4">
					<el-card ><el-button v-preventReClick @click="gotoQualitySituationList('81')" type="text">进度分析描述</el-button></el-card>
					<el-card ><el-button v-preventReClick @click="gotoQualitySituationList('82')" type="text">设计进度情况描述</el-button></el-card>
					<el-card ><el-button v-preventReClick @click="gotoQualitySituationList('83')" type="text">生产及技术准备情况描述</el-button></el-card>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<el-card class="blue_card">一周质量情况</el-card>
				</el-col>
				<el-col :span="4">
					<el-card class="blue_card">巡检情况</el-card>
				</el-col>
				<el-col :span="4">
					<el-card ><el-button v-preventReClick @click="gotoQualitySituationList('84')" type="text">巡检情况描述</el-button></el-card>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<el-card class="blue_card">安全生产/HSE情况</el-card>
				</el-col>
				<el-col :span="4">
					<el-card ><el-button v-preventReClick @click="gotoQualitySituationList('85')" type="text">安全生产/HSE情况描述</el-button></el-card>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<el-card class="blue_card">监理建议或意见</el-card>
				</el-col>
				<el-col :span="4">
					<el-card ><el-button v-preventReClick @click="gotoQualitySituationList('86')" type="text">监理建议或意见描述</el-button></el-card>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		data() {
			return {
				projectType: '0'
			}
		},
		components: {

		},
		mounted() {
			this.getProjectInfo()
		},
		methods: {
			gotoDetailedProgressList(){
				this.$router.push({path:'detailedProgressList'});
			},
			gotoQualitySituationList(type){
				this.$router.push({path:'qualitySituationList',query:{type: type}});
			},
			gotoProjectImportantList(){
				this.$router.push({path:'projectImportantList'});
			},
			getProjectInfo(){
				this.$http({
					url:  this.$store.state.httpUrl + "/index/infoName/"+$common.getItem("workNo"),
					method: "get",
					params: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.projectType = data.body.projectKind;
						if (!this.projectType){
							this.projectType = '2'
						}
					}
				});
			}
		}
	}
</script>

<style lang="scss">
	.demo_box{
		.el-row{
			margin-bottom: 10px;
		}
		.el-card{
			height: 50px;
		}
		.el-card__body{
			padding: 0 10px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
		.el-card ~ .el-card{
			margin-top: 5px;
		}
		a ~ a{
			display: block;
			margin-top: 5px;
		}
		.height2{
			height: 103px;
		}
		.height3{
			height: 160px;
		}
		.height4{
			height: 207px;
		}
		.blue_card{
			background-color: #d3e2f4;
			color: #00428e;
			.el-card__body{
				font-weight: bold;
			}
		}
	}
</style>
